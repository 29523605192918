<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-7 px-1 container"
    min-height="75vh"
  >
    <v-row
      v-if="!iframeVisible"
      class="d-flex justify-center"
    >
      <v-col
        cols="12"
        class="text-center text-h3 secondary--text font-weight-bold mt-12"
      >
        Please enter the name of your landlord, property management company, or real estate broker to get started.
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="d-flex justify-center align-center"
      >
        <v-text-field
          v-model="landlordName"
          outlined
          primary
          :rules="[v => !!v || 'Please enter a landlord name']"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row
      v-if="!iframeVisible"
      class="d-flex justify-center"
    >
      <v-col class="text-center">
        <v-btn
          color="primary"
          x-large
          class="font-weight-bold"
          :disabled="!landlordName"
          @click="checkLandlordName(landlordName)"
        >
          Submit
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="11">
        <!-- Display the iframe for Golden Equity -->
        <iframe
          v-show="landlord === 'Golden Equity' && iframeVisible"
          ref="iframeGoldenEquity"
          :src="`${this.iframeUrl}/004-tenants-program?property_manager_code=GoldenEquity`"
          width="100%"
          frameborder="0"
          scrolling="no"
          @load="onIframeLoad()"
        ></iframe>
        <!-- Display the iframe for DoorLoop -->
        <iframe
          v-show="landlord === 'DoorLoop' && iframeVisible"
          ref="iframeDoorLoop"
          :src="`${this.iframeUrl}/004-tenants?p_name=DoorLoop&p_office=STD`"
          width="100%"
          frameborder="0"
          scrolling="no"
          @load="onIframeLoad()"
        ></iframe>
        <!-- Display the iframe for CPPI -->
        <iframe
          v-show="landlord === 'CPPI' && iframeVisible"
          ref="iframeCPPI"
          :src="`${this.iframeUrl}/004-tenants-program?property_manager_code=CPPI`"
          width="100%"
          frameborder="0"
          scrolling="no"
          @load="onIframeLoad()"
        ></iframe>
        <!-- Display the iframe for Not listed/ Other -->
        <iframe
          v-show="landlord === 'Generic' && iframeVisible"
          ref="iframeOther"
          :src="`${this.iframeUrl}/004-tenants?p_name=Direct&p_office=STD`"
          width="100%"
          frameborder="0"
          scrolling="no"
          @load="onIframeLoad()"
        ></iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import iframeResize from 'iframe-resizer/js/iframeResizer'

  export default {
    data () {
      return {
        landlordName: null,
        landlord: null,
        iframeVisible: false // Control iframe visibility
      }
    },
    computed: {
      landlordOptions () {
        return ['Golden Equity', 'DoorLoop', 'CPPI', 'Not listed/ Other']
      },
      iframeUrl () {
        return process.env.VUE_APP_RENTER_IFRAME_URL
      }
    },
    methods: {
      checkLandlordName (inputString) {
        // Convert the input to lower case for case-insensitive comparison
        const lowerCaseString = inputString.toLowerCase()

        // Patterns for CPPI
        if (/cp|cpi|cpp|cppi realty|cppirealty/.test(lowerCaseString)) {
          this.landlord = 'CPPI'
          this.iframeVisible = true
          console.log('Landlord name: ', this.landlord)
          return
        }

        // Patterns for DoorLoop
        if (/door|loop|door loop|doorloop|dor lop/.test(lowerCaseString)) {
          this.landlord = 'DoorLoop'
          this.iframeVisible = true
          console.log('Landlord name: ', this.landlord)
          return
        }

        // Patterns for Golden Equity
        if (/golden equity|golden|equity|goldenequity/.test(lowerCaseString)) {
          this.landlord = 'Golden Equity'
          this.iframeVisible = true
          console.log('Landlord name: ', this.landlord)
          return
        }

        // Default return if none of the above conditions are met
        this.landlord = 'Generic'
        this.iframeVisible = true
        console.log('Landlord name: ', this.landlord)
      },
      loadLandlordIframe () {
        this.landlord = this.landlordName
      },
      onIframeLoad () {
        iframeResize({ log: false }, this.$refs.iframe)
      },
      submitChoice () {
        // Show the appropriate iframe based on the selected landlord
        this.iframeVisible = true
      }
    }
  }
</script>

<style scoped>
::v-deep .v-select .v-select__selections {
  font-size: 1.3rem !important;
}

.container {
  min-height: 75vh;
}
</style>
